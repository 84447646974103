import * as React from 'react';
import CustomButton from './components/CustomButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Grid, TextField, Typography, Button, Box } from '@mui/material';
import { getUserEmail, getFriendEmails } from './utils';

import InstructionPanel from './components/InstructionPanel';
import SpeedTest from './components/SpeedTest';
import Fade from '@mui/material/Fade';
import LoadingScreen from './components/LoadingScreen';
import BandwidthTest from './components/BandwidthTest';
import deployment_config from './deployment_config.json';

export default function TokenForm({
  email,
  setInstanceId,
  token,
  setToken,
  isMicEnabled,
  setIsMicEnabled,
  completedCaptcha,
  setCompletedCaptcha,
  setIsSubmitted,
  region,
  setRegion,
  setCurrentForm,
  setEmail,
}) {
  const [latency, setLatency] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [bandwidth, setBandwidth] = React.useState(0);
  const [isTokenValid, setIsTokenValid] = React.useState(true);
  const [friendEmails, setFriendEmails] = React.useState([]);
  const [invitationFrom, setInvitationFrom] = React.useState('');
  const apiBaseUrl = deployment_config.apiBaseUrl;

  const clear = () => {
    setToken('');
    setIsMicEnabled(false);
    setCompletedCaptcha(false);
    setIsSubmitted(false);
    setIsTokenValid(true);
  };

  const validateToken = (token) => {
    const trimmedToken = token.trim();
    const tokenRegex = /^[a-fA-F0-9]{16}$/;
    return token === trimmedToken && tokenRegex.test(trimmedToken);
  };

  const handleTokenChange = (e) => {
    const inputToken = e.target.value;
    setToken(inputToken);
    setIsTokenValid(validateToken(inputToken));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (token && isMicEnabled) {
        finalSubmit();
      }
    }
  };

  const enableMic = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        setIsMicEnabled(true);
      })
      .catch(err => {
        alert("We need to use your microphone to play our game. Please enable it and retry.");
      });
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const urlToken = queryParams.get('token');
    if (urlToken) {
      setToken(urlToken);
      setIsTokenValid(validateToken(urlToken));
    }

    const storedEmail = getUserEmail();
    if (!email) {
      setEmail(storedEmail);
    }

    async function fetchFriendEmails() {
      const storedFriendEmails = await getFriendEmails();
      if (storedFriendEmails) {
        setFriendEmails(storedFriendEmails);
      }
    }
    fetchFriendEmails();

    const urlInvitationFrom = queryParams.get('invitationFrom');
    if (urlInvitationFrom) {
      setInvitationFrom(urlInvitationFrom);
    }

    checkRegions().then(([region, latency]) => {
      setRegion(region);
      setLatency(latency);
    });
  }, []);

  async function checkRegions() {
    const regions = {
      'us-east-1': 'https://dynamodb.us-east-1.amazonaws.com/ping',
      'us-east-2': 'https://dynamodb.us-east-2.amazonaws.com/ping',
      'us-west-1': 'https://dynamodb.us-west-1.amazonaws.com/ping',
      'us-west-2': 'https://dynamodb.us-west-2.amazonaws.com/ping',
      'ca-central-1': 'https://dynamodb.ca-central-1.amazonaws.com/ping',
      'eu-west-2': 'https://dynamodb.eu-west-2.amazonaws.com/ping',
      'ap-southeast-2': 'https://dynamodb.ap-southeast-2.amazonaws.com/ping',
    };

    let fastestRegion = '';
    let fastestTime = Infinity;

    const regionPromises = Object.entries(regions).map(async ([region, url]) => {
      let startTime = Date.now();
      console.log(`Pinging ${region}`);

      try {
        await fetch(url, { mode: 'no-cors' });
        let endTime = Date.now();
        let timeTaken = endTime - startTime;

        console.log(`Pinging ${region} and it has a latency of ${timeTaken} ms`);

        if (timeTaken < fastestTime) {
          fastestTime = timeTaken;
          fastestRegion = region;
        }
      } catch (error) {
        console.error(`Error pinging ${region}: ${error}`);
      }
    });

    await Promise.all(regionPromises);
    console.log(fastestRegion);

    return [fastestRegion, fastestTime];
  }

  const createInstance = async () => {
    const requestBody = {
      email: email,
      region: region,
      token: token,
      latency: latency,
      bandwidth: bandwidth,
      friendEmails: friendEmails,
    };

    if (invitationFrom) {
      requestBody.invitationFrom = invitationFrom;
    }

    await fetch(apiBaseUrl + '/create-ec2-instance', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 304) {
          alert("PLAICraft thinks you're still playing on the account with this email, probably because you just disconnected or you recently quit and are rapidly rejoining. Our data gets messed up if you use the same email in two simultaneous sessions and it takes us some time to clean up after disconnects and quits. Please try a different email or wait a few minutes and try again.");
          throw new Error("Duplicate sessions with the same email");
        } else if (response.status === 429) { // Handling EC2 Quota Exceeded
          alert("We're experiencing a high volume of players, and our virtual machine quota has been exceeded. Please join back later.");
          throw new Error("EC2 quota exceeded");
        } else {
          return response.json().then(data => {
            let msg = JSON.stringify(data);
            if (msg.includes("No free licenses available")) {
              alert("All available Minecraft accounts are currently in use. Please try again later.");
            } else if (msg.includes("Email and/or token are invalid.")) {
              alert("Email and/or token are invalid.");
            } else if (msg.includes("Token is expired or invalid.")) {
              alert("Token is expired or invalid.");
            } else {
              alert("Something went wrong... please report this and describe what you were doing when this error occurred.");
            }
            throw new Error('Something went wrong.');
          });
        }
      })
      .then(async (data) => {
        setInstanceId(data);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const finalSubmit = async () => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    const isChromium = window.chrome;

    if (!isChromium || isMobileDevice) {
      setCurrentForm('browser-warning');
      return;
    }
    console.log("final submit");
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(async (stream) => {
        setLoading(true);
        try {
          await createInstance();
          setIsSubmitted(true);
          window.gtag_report_conversion(1);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      })
      .catch(err => {
        alert("We need to use your microphone to play our game. Please enable it and retry.");
        setIsMicEnabled(false);
      });
  };

  return (
    <>
      {isLoading ?
        <Fade in={isLoading} timeout={300}>
          <div>
            <LoadingScreen />
          </div>
        </Fade>
        :
        <form noValidate autoComplete="off" onKeyPress={handleKeyPress}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <InstructionPanel
                instructions={
                  <ol>
                    <li>Check your email for the token and enter it below to start playing.</li>
                    <li>Enable your microphone. Contributing means talking with other players.  If you do not talk with other players we may ban you from further participation.</li>
                    <li>Be patient.  Spinning up Minecraft in the cloud takes about 5 minutes.  Go to the bathroom.  Get a snack.  Be ready.</li>
                    <li>Once Minecraft starts you have to DOUBLE CLICK ON THIS BROWSER WINDOW/TAB once to go into full screen mode.  The mouse will not work properly if you do not do this.</li>
                    <li>DO NOT refresh this page or type CMD-W or CTRL-W while playing. If at any point there seems to be a password required, just click in; there's no password.</li>
                    <li>Have fun!</li>
                  </ol>
                }
                iframe={
                  <iframe
                    width="100%"
                    height="266"
                    src="https://www.youtube-nocookie.com/embed/Mu_Rl3MBXCE?autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                    allowFullScreen
                  >
                  </iframe>
                }
              />
            </Grid>
            <Grid item sx={{ marginTop: -2 }}>
              <Typography
                variant="head1"
                color="text.primary"
                align="left"
                sx={{
                  position: "relative",
                  top: 13,
                  fontWeight: 'bold'
                }}
              >
                Check your email for the token we just sent.  Check your spam folder too if you don't see an email from us in a minute or two.
              </Typography>
            </Grid>
            <Grid item sx={{ marginTop: 0 }}>
              <TextField
                label="Token"
                variant="outlined"
                fullWidth
                value={token}
                onChange={handleTokenChange}
                error={!isTokenValid}
                helperText={!isTokenValid ? "Token is in invalid form" : ""}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disabled={!token}
                color={isMicEnabled ? "success" : "error"}
                onClick={() => enableMic()}
                fullWidth
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                {isMicEnabled ? (
                  <MicIcon fontSize="medium" sx={{ color: "white" }} />
                ) : (
                  <>
                    <MicOffIcon fontSize="medium" />
                    <Typography
                      variant="body2"
                      color="inherit"
                      sx={{ ml: 1 }}
                    >
                      Click HERE to enable your microphone
                    </Typography>
                    <MicOffIcon fontSize="medium" />
                  </>
                )}
              </Button>
              <Box sx={{ position: "relative", height: "1.5em" }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    opacity: isMicEnabled ? 0 : 1,
                    transition: "opacity 0.3s ease-in-out",
                  }}
                >
                  You must enable your microphone to talk to other players.  This is a requirement to participate and play.
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    opacity: isMicEnabled ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                  }}
                >
                  Thank you! Please keep your environment QUIET as well!
                </Typography>
              </Box>
            </Grid>
            <Grid container item direction="row" alignItems="center" spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
              <Grid item sx={{ width: '50%' }}>
                <SpeedTest latency={latency} />
              </Grid>
              <Grid item sx={{ width: '50%' }}>
                <BandwidthTest bandwidth={bandwidth} setBandwidth={setBandwidth} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ visibility: (latency > 200 || bandwidth < 60) ? "visible" : "hidden" }}
              >
                <b>Note:</b> Your experience might be affected by poor connectivity.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
              >
                <b style={{ color: "red" }}>Note:</b> We are actively tracking the amount of time you talk, if you have played for too long without talking and interacting with others, we will ban you from further access!
              </Typography>
            </Grid>
            <Grid container item direction="row" alignItems="center" spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
              <Grid item>
                <CustomButton
                  isDisabled={!token || !isMicEnabled || !isTokenValid}
                  content="Submit"
                  color="success"
                  onClick={() => {
                    finalSubmit();
                  }}
                />
              </Grid>
              <Grid item ml={2}>
                <CustomButton
                  isDisabled={false}
                  content="Clear"
                  color="primary"
                  onClick={clear}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
    </>
  );
}
